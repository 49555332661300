const sidebarMenu = [
  {
    key: 'dashboard',
    path: '/',
    name: 'Dashboard',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2"
          y="2"
          width="9"
          height="9"
          rx="2"
          fill="currentColor"
        ></rect>
        <rect
          opacity="0.3"
          x="13"
          y="2"
          width="9"
          height="9"
          rx="2"
          fill="currentColor"
        ></rect>
        <rect
          opacity="0.3"
          x="13"
          y="13"
          width="9"
          height="9"
          rx="2"
          fill="currentColor"
        ></rect>
        <rect
          opacity="0.3"
          x="2"
          y="13"
          width="9"
          height="9"
          rx="2"
          fill="currentColor"
        ></rect>
      </svg>
    ),
    childs: null,
  },
  {
    key: 'listings',
    path: '/listings',
    name: 'Listings',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
          fill="currentColor"
        ></path>
        <path
          d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
    childs: [
      { path: '/listings/all', name: 'All Listings' },
      { path: '/listings/live', name: 'Live Listings' },
      { path: '/listings/sold', name: 'Sold Listings' },
      { path: '/listings/draft', name: 'Draft Listings' },
      { path: '/listings/dotd', name: 'Deals of the Day' },
      { path: '/categories', name: 'Categories' },
      { path: '/listings/activity-algo', name: 'Activity Algo' },
      { path: '/listings/vault', name: 'Vault' },
    ],
  },
  // {
  //   key: 'crm',
  //   path: '/crm',
  //   name: 'CRM',
  //   icon: (
  //     <svg
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         opacity="0.3"
  //         d="M7 20.5L2 17.6V11.8L7 8.90002L12 11.8V17.6L7 20.5ZM21 20.8V18.5L19 17.3L17 18.5V20.8L19 22L21 20.8Z"
  //         fill="currentColor"
  //       ></path>
  //       <path
  //         d="M22 14.1V6L15 2L8 6V14.1L15 18.2L22 14.1Z"
  //         fill="currentColor"
  //       ></path>
  //     </svg>
  //   ),
  //   childs: [
  //     { path: '/crm/leads/all', name: 'All Leads' },
  //     { path: '/crm/leads/deposit', name: 'Deposits' },
  //     { path: '/crm/leads/contact', name: 'Contact Form' },
  //     // { path: '/crm/deals', name: 'Deals' },
  //     { path: '/crm/clients', name: 'Clients' },
  //   ],
  // },
  {
    key: 'articles',
    path: '/articles',
    name: 'Articles',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.89999 21.9C5.89999 21.9 5.1 21.6 4.7 21.1C4.2 20.6 4 19.9 4 18.9V4.90002C4 3.90002 4.2 3.10007 4.7 2.70007C5.2 2.30007 5.89999 2 6.89999 2H13C13.9 2 14.7 2.10007 15.4 2.20007C16.1 2.30007 16.8 2.60002 17.4 2.90002C17.9 3.20002 18.3 3.6 18.7 4C19.1 4.5 19.4 5 19.6 5.5C19.8 6.1 19.9 6.70005 19.9 7.30005C19.9 9.00005 19.2 10.3 17.9 11.3C19.8 12.3 20.8 13.9 20.8 16C20.8 17.2 20.5 18.2001 19.9 19.2001C19.3 20.1001 18.5 20.9 17.4 21.3C16.8 21.6 16.1 21.7 15.3 21.9C14.5 22 13.6 22.1 12.6 22.1H6.89999V21.9ZM12.5 17.7001C15.3 17.7001 15.7 16.6 15.7 15.6C15.7 14.7 15.3 14.3 15 14.1C14.4 13.7 13.6 13.6 12.4 13.6H9.09999V17.7001H12.5ZM11.9 9.59998C12.8 9.59998 13.4 9.50002 13.9 9.40002C14.3 9.30002 14.5 9.00007 14.7 8.70007C14.9 8.40007 14.9 8.10005 14.9 7.80005C14.9 6.90005 14.5 6.6 14.3 6.5C13.7 6.2 12.8 6.09998 11.5 6.09998H9.09999V9.59998H11.9Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  {
    key: 'reviews',
    path: '/reviews',
    name: 'Reviews',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
          fill="currentColor"
        />
        <path
          d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  {
    key: 'boosts',
    path: '/boosts',
    name: 'Boosts',
    icon: (
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 16 16"
        height="1em"
        width="1em"
      >
        <path
          opacity="0.3"
          d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"
          fill="currentColor"
        />
        <path
          d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"
          fill="currentColor"
        />
        <path
          opacity="0.3"
          d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"
          fill="currentColor"
        />
      </svg>
    ),
    childs: null,
  },
  {
    key: 'crm',
    path: '/crm',
    name: 'CRM',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
          fill="currentColor"
        />
        <path
          opacity="0.3"
          d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
          fill="currentColor"
        />
      </svg>
    ),
    childs: [
      { path: '/crm/all', name: 'All Leads' },
      { path: '/crm/reminders', name: 'Reminders' },
      { path: '/crm/success', name: 'Success Leads' },
    ],
  },
  {
    key: 'users',
    path: '/users',
    name: 'Users',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
          fill="currentColor"
        ></path>
        <rect
          opacity="0.3"
          x="8"
          y="3"
          width="8"
          height="8"
          rx="4"
          fill="currentColor"
        ></rect>
      </svg>
    ),
    childs: [
      { path: '/users/all', name: 'All Users' },
      { path: '/users/sellers', name: 'Sellers' },
      { path: '/users/buyers', name: 'Buyers' },
    ],
  },
  {
    key: 'videoSearch',
    path: '/video-search/live',
    name: 'Video Search',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM14.5 4.5C10.4 4.5 7 7.9 7 12C7 16.1 10.4 19.5 14.5 19.5C18.6 19.5 22 16.1 22 12C22 7.9 18.6 4.5 14.5 4.5Z"
          fill="currentColor"
        />
        <path
          opacity="0.3"
          d="M22 12C22 16.1 18.6 19.5 14.5 19.5C10.4 19.5 7 16.1 7 12C7 7.9 10.4 4.5 14.5 4.5C18.6 4.5 22 7.9 22 12ZM12 7C9.2 7 7 9.2 7 12C7 14.8 9.2 17 12 17C14.8 17 17 14.8 17 12C17 9.2 14.8 7 12 7Z"
          fill="currentColor"
        />
      </svg>
    ),
    childs: [
      {
        path: '/video-search/video-promotion-live',
        name: 'Video Promotions Live',
      },
      {
        path: '/video-search/video-promotion-draft',
        name: 'Video Promotions Draft',
      },
      { path: '/video-search/live', name: 'Live' },
      { path: '/video-search/draft', name: 'Draft' },
    ],
  },
  {
    key: 'globallyLand',
    path: '/globally-land',
    name: 'GloballyLand',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          fill="currentColor"
        />
        <path
          d="M8.70001 6C8.10001 5.7 7.39999 5.40001 6.79999 5.10001C7.79999 4.00001 8.90001 3 10.1 2.2C10.7 2.1 11.4 2 12 2C12.7 2 13.3 2.1 13.9 2.2C12 3.2 10.2 4.5 8.70001 6ZM12 8.39999C13.9 6.59999 16.2 5.30001 18.7 4.60001C18.1 4.00001 17.4 3.6 16.7 3.2C14.4 4.1 12.2 5.40001 10.5 7.10001C11 7.50001 11.5 7.89999 12 8.39999ZM7 20C7 20.2 7 20.4 7 20.6C6.2 20.1 5.49999 19.6 4.89999 19C4.59999 18 4.00001 17.2 3.20001 16.6C2.80001 15.8 2.49999 15 2.29999 14.1C4.99999 14.7 7 17.1 7 20ZM10.6 9.89999C8.70001 8.09999 6.39999 6.9 3.79999 6.3C3.39999 6.9 2.99999 7.5 2.79999 8.2C5.39999 8.6 7.7 9.80001 9.5 11.6C9.8 10.9 10.2 10.4 10.6 9.89999ZM2.20001 10.1C2.10001 10.7 2 11.4 2 12C2 12 2 12 2 12.1C4.3 12.4 6.40001 13.7 7.60001 15.6C7.80001 14.8 8.09999 14.1 8.39999 13.4C6.89999 11.6 4.70001 10.4 2.20001 10.1ZM11 20C11 14 15.4 9.00001 21.2 8.10001C20.9 7.40001 20.6 6.8 20.2 6.2C13.8 7.5 9 13.1 9 19.9C9 20.4 9.00001 21 9.10001 21.5C9.80001 21.7 10.5 21.8 11.2 21.9C11.1 21.3 11 20.7 11 20ZM19.1 19C19.4 18 20 17.2 20.8 16.6C21.2 15.8 21.5 15 21.7 14.1C19 14.7 16.9 17.1 16.9 20C16.9 20.2 16.9 20.4 16.9 20.6C17.8 20.2 18.5 19.6 19.1 19ZM15 20C15 15.9 18.1 12.6 22 12.1C22 12.1 22 12.1 22 12C22 11.3 21.9 10.7 21.8 10.1C16.8 10.7 13 14.9 13 20C13 20.7 13.1 21.3 13.2 21.9C13.9 21.8 14.5 21.7 15.2 21.5C15.1 21 15 20.5 15 20Z"
          fill="currentColor"
        />
      </svg>
    ),
    childs: [
      {
        path: '/globally-land/dashboard',
        name: 'Dashboard',
      },
      {
        path: '/globally-land/listings/published',
        name: 'Live Listings',
      },
      {
        path: '/globally-land/listings/review',
        name: 'Under Review',
      },
      {
        path: '/globally-land/listings/rejected',
        name: 'Rejected Listings',
      },
    ],
  },
  // {
  //   key: 'activityLogs',
  //   path: '/activity-logs',
  //   name: 'Activity Log',
  //   icon: (
  //     <svg
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z"
  //         fill="currentColor"
  //       />
  //       <path
  //         opacity="0.3"
  //         d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z"
  //         fill="currentColor"
  //       />
  //     </svg>
  //   ),
  //   childs: [
  //     { path: '/activity-logs/subscriptions', name: 'Subscriptions' },
  //     { path: '/activity-logs/boosts', name: 'Boosts' },
  //     { path: '/activity-logs/billings', name: 'Billing' },
  //     { path: '/activity-logs/leads', name: 'Leads' },
  //   ],
  // },
]

export default sidebarMenu
