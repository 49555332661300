import { lazy } from 'react'

const LoginPage = lazy(() => import('../pages/auth/LoginPage'))
const DashboardPage = lazy(() => import('../pages/DashboardPage'))
const ListingsPage = lazy(() => import('../pages/listings/index'))
const VaultPage = lazy(() => import('../pages/listings/vault'))
const ArticlesPage = lazy(() => import('../pages/articles'))
const VideoSearchPage = lazy(() => import('../pages/video-search'))
const UsersListPage = lazy(() => import('../pages/users/index'))
const ReviewsPage = lazy(() => import('../pages/reviews/index'))
const CRMPage = lazy(() => import('../pages/crm/index'))
const ActivityLog = lazy(() => import('../pages/activity-log'))
const GLDashboardPage = lazy(() => import('../pages/globally-land/dashboard'))
const GLListingsPage = lazy(() => import('../pages/globally-land/listings'))
const BoostsPage = lazy(() => import('../pages/boosts/index'))

const appRouter = [
  {
    path: '/auth/login',
    Component: LoginPage,
  },
  {
    path: '/',
    Component: DashboardPage,
  },
  {
    path: '/listings/vault',
    Component: VaultPage,
  },
  {
    path: '/listings/:slug',
    Component: ListingsPage,
  },
  {
    path: '/users/:slug',
    Component: UsersListPage,
  },
  {
    path: '/articles',
    Component: ArticlesPage,
  },
  {
    path: '/reviews',
    Component: ReviewsPage,
  },
  {
    path: '/boosts',
    Component: BoostsPage,
  },
  {
    path: '/crm/:slug',
    Component: CRMPage,
  },
  {
    path: '/video-search/:slug',
    Component: VideoSearchPage,
  },
  {
    path: '/activity-logs/:slug',
    Component: ActivityLog,
  },
  {
    path: '/globally-land/dashboard',
    Component: GLDashboardPage,
  },
  {
    path: '/globally-land/listings/:status',
    Component: GLListingsPage,
  },
]

export default appRouter
