const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  GET_USER: 'user/GET_USER',
  REGISTER: 'user/REGISTER',
  ACCOUNT_INIT: 'user/ACCOUNT_INIT',
  RELOAD_USER_DATA: 'user/RELOAD_USER_DATA',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
}

export default actions
