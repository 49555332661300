import React from 'react'
import moment from 'moment'

import packageData from '../../../../package.json'
import styles from '../main.module.scss'

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p>{moment().format('YYYY')}© LandCentury</p>
        </div>
        <div className="ms-3">
          <p>v.{packageData.version}</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
