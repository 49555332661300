import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Router as RRRouter } from 'react-router-dom'

import IndexLayout from '../components/layouts/IndexLayout'

import routes from './app.router'

const Router = ({ history }) => {
  return (
    <RRRouter history={history}>
      <IndexLayout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <Switch location={location} key={location.pathname}>
                {routes.map(({ path, Component, exact }) => (
                  <Route
                    path={path}
                    key={path}
                    exact={exact || true}
                    render={() => (
                      <React.Suspense fallback={<div>Loading...</div>}>
                        <Component />
                      </React.Suspense>
                    )}
                  />
                ))}
                <Redirect to="/404" />
              </Switch>
            )
          }}
        />
      </IndexLayout>
    </RRRouter>
  )
}

export default Router
