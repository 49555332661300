import React from 'react'
import { Link } from 'react-router-dom'

import styles from './page-title.module.scss'

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <div className={styles.breadcrumbs}>
      <ul>
        {breadcrumbs.map((item, index) => (
          <li key={index}>
            {item.path ? (
              <Link to={item.path}>{item.name}</Link>
            ) : (
              <span>{item.name}</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Breadcrumbs
