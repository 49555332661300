import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Drawer, Button } from 'antd'
import { FaEnvelope } from 'react-icons/fa'
import {
  RiContactsBook2Fill,
  RiTaskFill,
  RiSettings2Fill,
} from 'react-icons/ri'

import styles from './user-drawer.module.scss'
import actions from '../../../../../redux/users/actions'
import defaultAvatar from '../../../../../assets/default-user.svg'
import localStorageHelper from '../../../../../utils/local-storage.util'

const profileMenu = [
  {
    path: '/',
    name: 'My Profile',
    help: 'Your profile info',
    icon: <RiContactsBook2Fill />,
  },
  { path: '/', name: 'My Tasks', help: 'Todo and tasks', icon: <RiTaskFill /> },
  {
    path: '/',
    name: 'Settings',
    help: 'PortalStation settings',
    icon: <RiSettings2Fill />,
  },
]

const UserDrawer = ({ user, visible, setVisibility }) => {
  const dispatch = useDispatch()

  return (
    <Drawer
      title="User Profile"
      visible={visible}
      onClose={() => setVisibility(false)}
      width={380}
      className="ps-drawer ps-drawer__user-menu"
    >
      <div className={styles.userDrawer}>
        <div className={styles.userInfo}>
          <div className={styles.avatar}>
            <img src={user.avatar || defaultAvatar} alt={user.name} />
          </div>
          <div className={styles.info}>
            <h3>{user.name}</h3>
            <p className="mb-0">
              <FaEnvelope /> <span>{user.email}</span>
            </p>
          </div>
        </div>
        <div className={styles.profileMenu}>
          <ul>
            {profileMenu.map((menu, index) => (
              <li key={index}>
                <Link to={menu.path}>
                  <div className={styles.menuItem}>
                    <div className={styles.icon}>{menu.icon}</div>
                    <div className={styles.menuName}>
                      <h4>{menu.name}</h4>
                      <p className="mb-0">{menu.help}</p>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
          <Button
            className="btn-primary btn-primary__light mt-4 fw-bold"
            onClick={() => {
              localStorageHelper.remove('apiToken')
              window.location.reload()
            }}
          >
            Sign Out
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default UserDrawer
