import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import MenuItem from './MenuItem'

import styles from '../../../main.module.scss'

const SidebarMenu = ({ sidebarMenu }) => {
  const [expandedMenu, setExpandedMenu] = useState([])

  return (
    <div className={styles.menuWrapper}>
      <PerfectScrollbar>
        <div className={styles.menu}>
          <ul>
            {sidebarMenu.map((menu) => (
              <li
                key={menu.key}
                className={
                  expandedMenu.includes(menu.key)
                    ? styles.expanded
                    : 'default-menu'
                }
              >
                <MenuItem
                  menu={menu}
                  withIcon
                  expandedMenu={expandedMenu}
                  setExpandedMenu={setExpandedMenu}
                />
                {menu.childs && (
                  <ul>
                    {menu.childs.map((child) => (
                      <li key={child.path}>
                        <MenuItem menu={child} withIcon={false} />
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </PerfectScrollbar>
    </div>
  )
}

export default SidebarMenu
