import React, { useState } from 'react'

import styles from './header.module.scss'
import defaultUser from './../../../assets/default-user.svg'
import UserDrawer from './components/user/UserDrawer'

const Header = ({ user }) => {
  const [userMenu, setUserMenu] = useState(false)

  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerWrapper}>
          <div />
          <div className={styles.rightMenu}>
            <div className={styles.rightMenuWrapper}>
              <div className={styles.userMenu}>
                <button onClick={() => setUserMenu(true)}>
                  <img src={defaultUser} alt={user.name} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <UserDrawer user={user} visible={userMenu} setVisibility={setUserMenu} />
    </>
  )
}

export default Header
