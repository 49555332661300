import { combineReducers } from '@reduxjs/toolkit'

import users from './users/reducers'

const createRootReducer = (routerReducer) =>
  combineReducers({
    router: routerReducer,
    users,
  })

export default createRootReducer
