const localStorageHelper = {
  get: (target) => {
    return localStorage.getItem(target)
  },
  set: (target, value) => {
    localStorage.setItem(target, value)
    return localStorageHelper.get(target)
  },
  remove: (target) => {
    localStorage.removeItem(target)
    return true
  },
}

export default localStorageHelper
