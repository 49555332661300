import { notification } from 'antd'

const axiosErrorUtil = (e) => {
  let errMessage = 'Что-то пошло не так, попробуйте перезагрузить страницу.'
  if (e.response) {
    errMessage = e.response.data.message
  }
  notification.error({
    message: 'Error',
    description: errMessage,
  })
  return true
}

export default axiosErrorUtil
